<template>
  <div v-if="$role(['director', 'head_operation'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="px-2 pt-4 pb-0 pb-sm-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            left
            offset-y
            :close-on-content-click="false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Date Ranges"
                append-icon="mdi-calendar-outline"
                readonly
                outlined
                dense
                hide-details
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="dates = []"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              light
              class="force-light-font"
              range
            >
              <v-spacer />
              <v-btn
                text
                class="text-capitalize"
                small
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="dates.length !== 2"
                text
                class="text-capitalize"
                small
                @click="$refs.menu.save(dates), TRX_GET(tableSearch)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-6 pt-0 pt-sm-4 pr-0 pr-sm-4 pb-8">
        <div class="d-flex fill-width justify-space-between align-center text-h4 pt-4">
          Debit (Order)
          <span class="title primary--text">
            {{ $price(tableTotalAmount) }}
          </span>
        </div>
        <v-divider class="my-4" />
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                RDMS/PAYMENT/DB/{{ item.id }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.source`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                RDMS/-/TRX/{{ item.trx }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                {{ $price(item.amount) }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                {{ $localDT(item.created, 'datetimedefault') }}
              </span>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="col-sm-6 pt-0 pt-sm-4 pl-0 pl-sm-4 pb-8">
        <div class="d-flex fill-width justify-space-between align-center text-h4 pt-4">
          Credit (Purchase)
          <span class="title red--text">
            {{ $price(tableTotalAmountCR) }}
          </span>
        </div>
        <v-divider class="my-4" />
        <v-data-table
          dense
          :headers="headers"
          :items="tableCR"
          item-key="id"
          :server-items-length="tableTotalCR"
          :page.sync="tablePageCR"
          :items-per-page.sync="tableLimitCR"
          :options.sync="optionsCR"
          :footer-props="{
            itemsPerPageOptions: [50, 100, 250]
          }"
          :loading="tableLoadingCR"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                RDMS/PAYMENT/CR/{{ item.id }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.source`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                RDMS/PURCHASE/{{ item.stock_request }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                {{ $price(item.amount) }}
              </span>
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="d-flex align-center text-uppercase">
              <span class="font-weight-bold caption">
                {{ $localDT(item.created, 'datetimedefault') }}
              </span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Finance',
  data: () => ({
    activePicker: null,
    customerFilter: 1000,
    headers: [
      { text: 'PAYMENT ID', value: 'id' },
      { text: 'TRX', value: 'source', sortable: false },
      { text: 'Amount', value: 'amount', align: 'right' },
      { text: 'Created', value: 'created' }
    ],
    tableSearch: '',
    tableLoading: false,
    table: [],
    tableTotalAmount: 0,
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 50,
    options: {},
    tableSearchCR: '',
    tableLoadingCR: false,
    tableCR: [],
    tableTotalAmountCR: 0,
    tableTotalCR: 0,
    tablePageCR: 1,
    tableLimitCR: 50,
    optionsCR: {},
    menu: false,
    dates: []
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  watch: {
    dates (v) {
      if (v.length === 2) {
        if (!this.tableLoading) {
          this.FINANCE_LOAD()
        }
      } else {
        this.FINANCE_LOAD()
      }
    },
    options () {
      this.FINANCE_GET('debit')
    },
    optionsCR () {
      this.FINANCE_GET('credit')
    }
  },
  mounted () {
    // this.FINANCE_LOAD()
    this.dates = [this.$localDT('', 'datedefault'), this.$localDT('', 'datedefault')]
  },
  methods: {
    FINANCE_LOAD () {
      this.FINANCE_GET('debit')
      this.FINANCE_GET('credit')
    },
    FINANCE_GET (type) {
      type = type || 'credit'
      if (type === 'credit') {
        this.tableLoadingCR = true
        this.tableCR = []
        this.tableTotalCR = 0
        this.tableTotalAmountCR = 0
      } else {
        this.tableLoading = true
        this.table = []
        this.tableTotal = 0
        this.tableTotalAmount = 0
      }
      const { sortBy, sortDesc, page, itemsPerPage } = type === 'credit' ? this.optionsCR : this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('trx/FINANCE_SEARCH', {
        type,
        q: (query + ('&from=' + (this.dates[0] || '')) + ('&to=' + (this.dates[1] || '')))
      })
        .then((res) => {
          if (res.status) {
            if (type === 'credit') {
              this.tableCR = res.data.data.data
              this.tableTotalCR = parseInt(res.data.data.total) || 0
              this.tableTotalAmountCR = parseInt(res.data.data.total_amount) || 0
            } else {
              this.table = res.data.data.data
              this.tableTotal = parseInt(res.data.data.total) || 0
              this.tableTotalAmount = parseInt(res.data.data.total_amount) || 0
            }
          } else {
            if (type === 'credit') {
              this.tableCR = []
              this.tableTotalCR = 0
              this.tableTotalAmountCR = 0
            } else {
              this.table = []
              this.tableTotal = 0
              this.tableTotalAmount = 0
            }
          }
          if (type === 'credit') {
            this.tableLoadingCR = false
          } else {
            this.tableLoading = false
          }
        })
    }
  }
}
</script>
